<template>
  <div>
    <skeleton-preloader-profile-layout>
      <template #desktop>
        <table-skeleton-preloader :isSkeletonLoading="true" />
      </template>

      <template #mobile>
        <card-skeleton-preloader :isSkeletonLoading="true" />
      </template>
    </skeleton-preloader-profile-layout>
  </div>
</template>
